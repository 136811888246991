.buttonDiv {
  background-color: var(--blue);
  color: white;
  padding: 0.7rem;
  margin: 16px;
  width: 45%;
  text-align: center;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
}
