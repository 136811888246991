.brochure-grid {
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: auto auto auto;
  justify-content: space-around;
  grid-gap: 50px 0px; */
}
.brochure-grid img {
  height: 300px;
  width: 225px;
  margin: 10px;
}
@media only screen and (max-width: 1200px) {
  .brochure-grid img {
    height: 250px;
    width: 200px;
  }
}
@media only screen and (max-width: 1050px) {
  .brochure-grid img {
    height: 200px;
    width: 170px;
  }
}
/* @media only screen and (max-width: 850px) {
  .brochure-grid img {
    height: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .brochure-grid img {
    height: 200px;
  }
}
@media only screen and (max-width: 650px) {
  .brochure-grid img {
    height: 170px;
  }
}
@media only screen and (max-width: 600px) {
  .brochure-grid img {
    height: 150px;
  }
} */
@media only screen and (max-width: 480px) {
  .brochure-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  /* .brochure-grid img {
    height: 200px;
  } */
}
/* @media only screen and (max-width: 450px) {
  .brochure-grid {
    grid-gap: 20px 0px;
  }
}
@media only screen and (max-width: 400px) {
  .brochure-grid img {
    height: 150px;
  }
} */
