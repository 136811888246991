.sidebar-ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.sidebar-ul:first-child {
  margin-top: 20px;
}

.sidebar-nav-li {
  color: var(--blue);
  text-decoration: none;
  font-size: 19px;
  font-weight: 500;
  display: block;
  padding-bottom: 34px;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
}

.sidebar-nav-li-active {
  color: var(--gray);
}
.sidebar-spl{
  color: var(--blue);
  text-decoration: none;
  font-size: 19px;
  font-weight: 500;
  display: block;
  padding-bottom: 34px;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
}
.sidebar-spl a{
  text-decoration: none;
  color: var(--blue);
}
