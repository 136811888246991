.photo-item {
  height: fit-content;
  word-wrap: break-word;
}
.photo-item img {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 5%;
  object-fit: fill;
  margin-bottom: 10px;
  box-shadow: 0px 5px 5px rgb(182, 182, 182);
}
.photo-item p {
  text-align: center;
  line-height: 22px;
  color: var(--gray);
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 300;
}

.person-name {
  font-weight: 600 !important;
  color: var(--blue) !important;
}
.person-position {
  font-weight: 400 !important;
  color: var(--blue) !important;
}

@media only screen and (max-width: 1080px) {
  .photo-item img {
    width: 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 1010px) {
  .photo-item img {
    width: 130px;
    height: 130px;
  }
  .photo-item p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 950px) {
  .photo-item p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .photo-item img {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 650px) {
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 480px) {
  .photo-item p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 350px) {
  .photo-item img {
    width: 90px;
    height: 90px;
  }
}
