.parent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 100vw;
}
.select{
    background: transparent;
    outline: none;
    border: none;
    color: var(--blue);
    font-weight: 900;
    font-size: 35px;
}
.Table {
    width:100%;
    border-collapse: collapse;
    text-align: center;
}

.Table th {
    background-color:  #2A2A53;
    color: white;
    padding: 10px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
}

/* .Table tr:nth-child(even) {
    background-color: #DADADA;;
} */

.Table td, .Table th {
    letter-spacing: 1px;
    border-radius: 7px;
    padding: 10px;
    color: black;
    box-shadow: 1px -4px 4px rgba(0, 0, 0, 0.23);
    font-weight: bold;
    min-height:30px;
}
select{
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom:40px;
    outline:0;
    font-size: 40px;
    border: none;
    background-color: transparent;
    color:#2A2A53;
    border-bottom: 2px solid #2A2A53;
    padding-left: 2px;
}
.company{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}
.company_element{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 60%;
    flex-wrap: wrap;
}
.company_name{
    width: 70%;
}
@media only screen and (max-width:1000px){
    .logoImage{
        display: none;
    }
    .company_name{
        width: 100%;
    }
}
@media only screen and (max-width:900px){
    .parent{
        min-width: 70vw;
    }
    .company{
        height: 75px;
    }
}
@media only screen and (max-width:600px){
    .Table td, .Table th{
        padding: 10px 5px;
    }
    .select{
        font-size: 20px;
    }
    .Table th{
        font-size: 1rem;
    }
    .company{
        height: 60px;
    }
}
@media only screen and (max-width:500px){
    .Table th{
        font-size: 0.7rem;
    }
    .Table td, .Table th{
        padding: 7px 3px;
        font-weight: 400;
    }
    .Table td{
        font-size: 0.7rem;
    }
    .company{
        height: 40px;
    }
}


