.student-verification {
  color: var(--gray);
  font-size: 18px;
  line-height: 27px;
}
.student-verification ul {
  font-weight: 300;
}
.student-verification ul li {
  margin: 20px 0;
}
