.Breadcrumb-container {
  display: flex;
  flex-direction: row;
  z-index: 2;
  margin-top: 80px;
  padding: 10px 130px;
  position: sticky;
  top: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
	-webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  border: none;
  color: (--blue);
}
.Breadcrumb-divider {
  pointer-events: none;
  cursor: default;
  padding: 0 4px;
}
.Breadcrumb-navlink {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 1400px) {
  .Breadcrumb-container {
    padding: 10px 110px;
  }
}
@media only screen and (max-width: 1300px) {
  .Breadcrumb-container {
    padding: 10px 90px;
  }
}
@media only screen and (max-width: 1200px) {
  .Breadcrumb-container {
    padding: 10px 80px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 1100px) {
  .Breadcrumb-container {
    top: 80px;
    padding: 7px 60px;
  }
}
@media only screen and (max-width: 1100px) {
  .Breadcrumb-container {
    padding: 7px 50px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .Breadcrumb-container {
    padding: 6px 40px;
    top: 70px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 800px) {
  .Breadcrumb-container {
    padding: 6px 30px;
    top: 65px;
    font-size: 11px;
  }
}
@media only screen and (max-width: 700px) {
  .Breadcrumb-container {
    padding: 5px 25px;
    font-size: 10px;
    top: 55px;
  }
}
@media only screen and (max-width: 600px) {
  .Breadcrumb-container {
    top: 50px;
  }
}
