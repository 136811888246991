.company-registration {
  height: 300px;
}

.show-form-btn {
  cursor: pointer;
  color: #2a2a56;
  text-decoration: underline;
}

.company-registration-form {
  border: none;
  width: 100%;
  height: 300px;
}
