/* .box1 {
  display: flex;
  background-image: url("committees.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% 85%;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 20px;
  margin-top: 70px;
  height: 100px;
} */

.committee-title {
  background-image: url("../../assets/committees.jpg");
  background-position: 89% 93%;
  background-size: cover;
  box-shadow:inset 0 0 0 2000px rgba(42,42,83,0.2);
}
