.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
	-webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px rgb(224, 224, 224) solid;
  padding: 10px 50px;
  box-sizing: border-box;
  z-index: 3;

}
.navbar-dropdown-container {
  display: none;
  position: absolute;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  background-color: white;
  width: max-content;
  z-index: 4;
  margin-top: 0px;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.navbar-dropdown-container a {
  text-decoration: none;
  color: var(--blue);
  padding: 10px;
  display: block;
  width: fit-content;
  font-size: 16px;
  text-transform: capitalize;
}
.navbar-inner-container {
  position: relative;
}
.navbar-inner-container:hover .navbar-dropdown-container {
  display: block;
}
.mobile-navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
	-webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  border-bottom: 1px rgb(224, 224, 224) solid;
  padding: 5px 25px;
  box-sizing: border-box;
  z-index: 3;
  display: block;
}
.sidebar-container {
  position: fixed;
  top: 0;
  margin-left: 20vw;
  bottom: 0;
  z-index: 4;
  height: 100vh;
  width: 80vw;
  background-color: white;
  padding-top: 50px;
  overflow-y: scroll;
}
.sidebar-tabs-0 {
  position: relative;
  display: block;
  padding: 20px 40px;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 0.3px solid #70707073;
  
}
.sidebar-tabs-1 {
  position: relative;
  display: block;
  padding: 5px 1px;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
}
.sidebar-tabs-1:first-of-type {
  margin-top: 10px;
}
.sidebar-tabs-2 {
  position: relative;
  display: block;
  padding: 5px;
  padding-left: 45px;
  font-weight: 400;
  margin: 0 -40px;
  font-size: 12px;
  background-color: #eee;
}
.menu-icon {
  position: absolute;
  top: 5px;
  right: 20px;
  height: 30px;
  margin-top: 5px;
  fill: var(--blue);
}
#admissions-tab-23 {
  position: relative;
}
#new-anim-23 {
  position: absolute;
  top: 20px;
  right: 0;
  background: rgb(255, 37, 37);
  color: white;
  padding: 2px 5px;
  margin: 0;
  border-radius: 5px;
  font-weight: 300;
}
#new-badge {
  position: absolute;
  top: 20px;
  right: 0;
  background: rgb(255, 37, 37);
  color: white;
  padding: 2px 5px;
  margin: 0;
  border-radius: 5px;
  font-weight: 300;
  animation: fade-in 1s ease-in-out forwards infinite alternate;
}

@keyframes fade-in {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
/*
.menu-icon:active .sidebar-container {
  right: 0;
}
*/
.menu-arrow {
  height: 10px;
  margin-left: 5px;
}
.mec-logo {
  height: 70px;
}
.mec-logo-tab {
  width: 250px;
}
.other-tabs {
  font-size: 16px;
  height: fit-content;
  text-decoration: none;
  color: var(--blue);
  font-weight: 500;
}
.active-tabs {
  font-weight: 600;
  border-bottom: 1px solid var(--blue);
  padding-bottom: 2px;
  margin-bottom: -2px;
}

@media only screen and (max-width: 1440px) {
  .mec-logo-tab {
    width: 200px;
  }
}

@media only screen and (max-width: 1400px) {
  .navbar-container {
    padding: 10px 50px;
  }
}
@media only screen and (max-width: 1300px) {
  .navbar-container {
    padding: 10px 50px;
  }
}
@media only screen and (max-width: 1200px) {
  .navbar-container {
    padding: 10px 50px;
  }
  .mec-logo-tab {
    width: 150px;
  }
  .other-tabs {
    font-size: 15px;
  }
  .navbar-dropdown-container a {
    font-size: 15px;
  }
  .mec-logo {
    height: 60px;
  }
}
@media only screen and (max-width: 1100px) {
  .navbar-container {
    padding: 7px 30px;
    height: 80px;
  }
  .mec-logo-tab {
    width: 130px;
  }
  .other-tabs {
    font-size: 14px;
  }
  .navbar-dropdown-container a {
    font-size: 14px;
  }
  .mec-logo {
    height: 55px;
  }
}
@media only screen and (max-width: 1000px) {
  .navbar-container {
    padding: 7px 20px;
    height: 80px;
  }
  .mec-logo-tab {
    width: 120px;
  }
  .other-tabs {
    font-size: 13px;
  }
  .navbar-dropdown-container a {
    font-size: 13px;
    padding: 8px;
  }
  .mec-logo {
    height: 55px;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-container {
    padding: 5px 20px;
    height: 70px;
  }
  .mec-logo-tab {
    width: 110px;
  }
  .other-tabs {
    font-size: 12px;
  }
  .navbar-dropdown-container a {
    font-size: 12px;
  }
  .mec-logo {
    height: 50px;
  }
}
@media only screen and (max-width: 800px) {
  .navbar-container {
    padding: 7px 20px;
    height: 65px;
  }
  .mec-logo-tab {
    width: 110px;
  }
  .other-tabs {
    font-size: 10px;
  }
  .navbar-dropdown-container a {
    font-size: 10px;
    padding: 6px;
  }
  .mec-logo {
    height: 50px;
  }
}
@media only screen and (max-width: 700px) {
  .navbar-container {
    padding: 5px 20px;
    height: 55px;
  }
  .mec-logo-tab {
    width: 100px;
  }
  .other-tabs {
    font-size: 9px;
  }
  .navbar-dropdown-container a {
    font-size: 9px;
  }
  .mec-logo {
    height: 45px;
  }
}
@media only screen and (max-width: 600px) {
  .navbar-container {
    padding: 5px 20px;
    height: 50px;
  }
  .mec-logo-tab {
    width: 90px;
  }
  .other-tabs {
    font-size: 8px;
  }
  .navbar-dropdown-container a {
    font-size: 8px;
    padding: 5px;
  }
  .mec-logo {
    height: 40px;
  }
}
@media only screen and (max-width: 550px) {
  .navbar-container {
    padding: 5px 20px;
    height: 50px;
  }
  .mec-logo-tab {
    width: 80px;
  }
  .other-tabs {
    font-size: 7px;
  }
  .navbar-dropdown-container a {
    font-size: 7px;
  }
  .mec-logo {
    height: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .navbar-container {
    padding: 5px 18px;
    height: 50px;
  }
  .mec-logo-tab {
    width: 80px;
  }
  .other-tabs {
    font-size: 7px;
  }
  .mec-logo {
    height: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar-container {
    display: none;
  }
}
@media only screen and (max-width: 432px) {
  .sidebar-tabs {
    font-size: 18px;
    padding-right: 10px;
  }
  .menu-arrow {
    height: 8px;
  }
}
@media only screen and (max-width: 360px) {
  .sidebar-tabs {
    font-size: 16px;
    padding-right: 7px;
    padding-left: 25px;
  }
  .menu-arrow {
    height: 7px;
  }
}
