.admission{
    margin: 150px 20px 20px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.admission p {
    width: 70%;
    text-align: center;
    color: var(--blue);
    font-size: 20px;
}
.Flyer{
    text-decoration: none;
    color: white;

    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 10px;
}
.admission2k23holder{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(249, 248, 248);
}
.programmes{
    color: rgb(13, 13, 31);
    padding: 20px 10px;
    border-radius: 20px;
}
.breadcrumb{
    background-image: url('../../assets/admissions2023.png');
    min-height: 500px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shadow{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 500px;
    background: linear-gradient(#e66465, #9198e5);
    opacity: 0.5;
    z-index: 1;
}
.shadow h1{
    margin-top: 200px;
    opacity: 1;
    z-index: 10;
    color: white;
}
.ugprogrammes{
    align-self: flex-start;
    margin: 20px;
    background: var(--red);
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
}
.admissioncardholder{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;
    padding: 50px 100px;
}
.linegraph{
    width: 70%;
    display: flex;
}
.merit-regulated{
    width: 50%;
    background: var(--red);
    height: fit-content;
    padding-block: 5px;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.merit-full-fees{
    width: 45%;
    background: var(--blue);
    height: fit-content;
    padding-block: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nri-fees{
    width: 5%;
    background: rgb(59, 139, 231);
    height: fit-content;
    color: white;
    padding-block: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.legends{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 50%;
    justify-content: center;
}
.merit-legend{
    background: var(--red);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
}
.manag-legend{
    background: var(--blue);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
}
.nri-legend{
    background: rgb(59, 139, 231);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
}
.seatmatrix{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.fee{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 30vw;
}
.fee-badge{
    padding: 10px 20px;
    background: var(--red);
    color: white;
    border-radius: 10px;
}
.feestructure{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fee-table {
    width: 50%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .fee-table th,
  .fee-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .fee-table th {
    background-color: var(--blue);
    color: white;
  }
  
  .fee-table td {
    background-color: white;
  }
  @media only screen and (max-width: 900px){
    .legends{
        width: 80vw;
    }
  }
  @media only screen and (max-width: 500px){
    .admissioncardholder{
        padding: 0px 20px;
    }
    .linegraph{
        width: 95%;
    }
    .legends{
        width: 100vw;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 0px 10px;
    }
    .merit-legend{
        font-size: 14px;
        }
    .manag-legend{
        font-size: 14px;
    }
    .nri-legend{
        font-size: 14px;
    }
  }