.admissionscard {
    border: 2px solid var(--blue);
    border-radius: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 30px 10px;
    background: white;
    width: 22vw;
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
.admissionscard h3{
  width: 95%;
}
  .badgeholder {
    display: flex;
    /* gap: 5px; */
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    width: 95%;
  }
  .badgeholder p {
    margin: 10px 0px;
  }
  
  .course-admission2k23,
  .seats-admission2k23 {
    background: var(--blue);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
  }
  #newbadge{
    background: rgb(255, 37, 37);
    color: white;
    padding: 2px 5px;
    margin: 0;
    border-radius: 5px;
    font-weight: 300;
    position: absolute;
    top: 20px;
    right: 10px;
    transform: rotateZ(30deg);
  }
  .Flyer{
    align-self: center;
    background: var(--red);
  }
  
  /* Media Query for Responsive Styling */
  @media (max-width: 900px) {
    .admissionscard {
      width: 50vw; /* Adjust the width as needed for smaller screens */
    }
  }
  @media only screen and (max-width: 500px){
    .badgeholder{
      min-height: 75px;
      grid-gap: 0px;
      gap: 0px;
      flex-wrap: wrap;
    }
    .admissionscard{
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
    }
    .badgeholder{
      width: 90%;
    }
    .admissionscard h3{
      margin: 0;
    }
  }
  