.mobileSidebar-container {
  position: absolute;
  padding: 40px 60px;
  top: 40px;
  right: 0;
  width: 100%;
}
.mobileSidebar-nav-container {
  background-color: white;
  margin-top: 25px;
  padding: 4px;
  border-radius: 8px;
  width: 100%;
  border-bottom: solid 1px var(--gray);
}
.mobileSidebar-nav {
  z-index: 1;
  color: var(--blue);
  font-size: 22px;
  margin: 15px 0;
}
.mobile-sidebar-arrow {
  height: 15px;
  cursor: pointer;
  float: right;
}
.mobileSidebar-nav a{
  text-decoration: none;
  color: var(--blue);
}
@media only screen and (max-width: 480px) {
  .mobileSidebar-container {
    padding: 40px 20px;
  }
}
