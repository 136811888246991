.lazy-img {
  display: block;

}

@keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.lazy-img.loaded:not(.has-error) {
  animation: loaded 300ms ease-in-out;
}

.lazy-img.has-error {
  content: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}
