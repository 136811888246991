.contact-details {
  font-size: 18px;
  color: var(--gray);
}
.contact-details ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.contact-details ul li {
  margin: 5px 0;
  font-weight: 300;
}
.contact-details p img {
  display: inline;
}
.placement-comittee-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.placement-comittee-grid h3 {
  font-weight: 400;
  color: var(--blue);
}
.placement-comittee-grid-left {
  width: fit-content;
}
