.special_p {
  margin-top: 50px;
  padding: 0 200px;
  position: relative;
}
.placement-stats p {
  text-align: center;
}
.statistics-button {
  padding: 20px 0;
  text-align: right;
  display: block;
  font-weight: 500;
  font-size: 18px;
}
.statistics-button img {
  width: 27px;
  display: inline;
  margin-left: 7px;
}
.placement-departments-container {
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
}
.round-circle {
  height: 30px;
  width: 30px;
  justify-self: center;
  border-radius: 50%;
  margin: 0 auto;
}
.color-1 {
  background-color: #b4c5e4;
}
.color-2 {
  background-color: #8aea92;
}
.color-3 {
  background-color: #ffee93;
}
.color-4 {
  background-color: #ffc870;
}
.placement-department {
  max-width: 20%;
}
.placement-department p {
  text-align: center;
  margin-top: 5px;
}
.placement-year {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  outline: none;
  border: none;
  color: var(--blue);
  font-weight: 900;
  font-size: 35px;
}
.placement-stats-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-around;
  grid-row-gap: 30px;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 150px 0 100px 0;
}
.placement-year option {
  font-size: 25px;
}
.internship-procedure ul {
  font-size: 20px;
  font-weight: 300;
  list-style-type: disc;
}
.internship-procedure ul li {
  margin: 20px 0;
  color: var(--gray);
}
@media only screen and (max-width: 1200px) {
  .special_p {
    margin-top: 50px;
    padding: 0 200px;
  }
}
@media only screen and (max-width: 1100px) {
  .special_p {
    margin-top: 50px;
    padding: 0 150px;
  }
}
@media only screen and (max-width: 1000px) {
  .special_p {
    margin-top: 50px;
    padding: 0 80px;
  }
}
@media only screen and (max-width: 900px) {
  .special_p {
    margin-top: 50px;
    padding: 0 80px;
  }
}
@media only screen and (max-width: 800px) {
  .placement-stats-grid {
    grid-template-columns: auto auto auto;
  }
}
@media only screen and (max-width: 700px) {
  .special_p {
    margin-top: 50px;
    padding: 0 70px;
  }
  .placement-department p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .special_p {
    margin-top: 50px;
    padding: 0 60px;
  }
}
@media only screen and (max-width: 550px) {
  .special_p {
    margin-top: 50px;
    padding: 0 50px;
  }
}
@media only screen and (max-width: 480px) {
  .special_p {
    margin-top: 50px;
    padding: 0 30px;
  }
  .placement-stats-grid {
    grid-template-columns: auto auto;
  }
}
@media only screen and (max-width: 370px) {
  .placement-department p {
    font-size: 8px;
  }
}
