.page {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.page-title {
  /* display: grid;
  grid-column: 1/5; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  width: 100vw;
  background-image: url("../assets/Departments/dept-img.jpg");
  background-size: cover;
}

.page-title h1 {
  color: white;
  font-weight: 900;
  font-size: 50px;
  letter-spacing: 1px;
  margin-top: 22vh;
  text-align: center;
}

.page-nav {
  display: grid;
  color: var(--blue);
  /* grid-auto-rows: 20px; */
  gap: 50px;
  padding-top: 40px;
  margin-left: 75px;
}

.page-nav a h2 {
  color: var(--blue);
  font-weight: 500;
}

.page-content {
  padding: 40px 60px;
  position: relative;
}

.ed-obj {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px; */
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .page-title h1 {
    margin-top: 18vh;
  }

  .page {
    display: grid;
    grid-template-columns: 100%;
  }

  .page-nav {
    display: none;
  }
}

.other-title {
  background: var(--blue);
}

/* .other-container {
  margin-top: 50px;
  padding: 0 220px;
} */

.other-list {
  list-style-type: disc;
  line-height: 40px;
  font-size: 18px;
  color: var(--blue);
  padding-left: 15px;
  width: fit-content;
  /* margin: auto; */
}

.grid {
  display: grid;
}

.function-list h2 {
  margin-top: 15vh;
  font-weight: 600;
}
.function-list ul {
  list-style-type: disc;
  padding-left: 0;
  font-weight: 300;
  line-height: 27px;
  font-size: 18px;
}

.function-list ul li {
  margin-bottom: 10px;
  color: var(--gray);
}

@media (max-width: 768px) {
  .function-list h2 {
    font-size: 12px;
    font-weight: 500;
  }
}

.list-image-container {
  display: inline-grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 50px 50px;
}
@media only screen and (max-width: 480px) {
  .list-image-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 50px 50px;
  }
  .page-content {
    padding: 40px 20px;
  }
  .page-title h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 350px) {
  .list-image-container {
    grid-gap: 5px 10px;
  }
}

.margin-0 {
  margin: 0;
}
