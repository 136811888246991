/* .box1 {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% 85%;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 20px;
  margin-top: 70px;
  height: 100px;
} */

/* .placement-sidebar-tab h2 {
  color: var(--blue);
  font-weight: 500;
}
.placement-sidebar-heading {
  font-weight: 700;
  color: var(--blue);
  font-size: 35px;
}

*/

.placement-sidebar {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

/* .placement-sidebar-title {
  display: grid;
  grid-column: 1/5;
  height: 40vh;
  width: 100vw;
  background-position: 89% 93%;
  background-size: cover;
} */

/* .placement-sidebar-title h1 {
  color: white;
  font-weight: 900;
  font-size: 50px;
  letter-spacing: 1px;
  margin-top: 22vh;
  text-align: center;
}

.placement-sidebar-nav {
  display: grid;
  color: var(--blue);
  grid-auto-rows: 20px;
  gap: 50px;
  padding-top: 40px;
  margin-left: 75px;
}

.placement-sidebar-nav a h2 {
  color: var(--blue);
  font-weight: 500;
} */

@media (max-width: 768px) {
  /* .placement-sidebar-title h1 {
    margin-top: 18vh;
  } */

  .placement-sidebar {
    display: grid;
    grid-template-columns: 100%;
  }

  /* .placement-sidebar-nav {
    display: none;
  } */
  .placement-sidebar-content {
    padding: 40px 20px 40px 20px;
  }
}
