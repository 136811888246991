:root {
  --blue: #2a2a53;
  --red: #fd2d34;
  --gray: #8585a8;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.custom-a {
  text-decoration: none;
  color: var(--blue);
}
.custom-p {
  color: var(--gray);
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  /* text-align: justify; */
}
.dark-p {
  color: var(--blue);
  font-size: 18px;
  line-height: 27px;
}
.custom-h2 {
  color: var(--blue);
  font-size: 32px;
  font-weight: 750;
}
.custom-h3 {
  color: var(--blue);
  font-weight: 500;
}

.tdn {
  text-decoration: none;
  color: inherit;
}

.section-heading {
  text-align: center;
  font-weight: 900;
  color: var(--blue);
  letter-spacing: 0.5px;
  margin: 0;
}
.section-heading-underline {
  /* background-color: #fd2d34; */
  /* opacity: 0.5; */
  width: 50px;
  height: 5px;
  margin: 5px auto;
}

@media only screen and (max-width: 700px) {
  .section-heading {
    font-size: 26px;
  }
}
@media only screen and (max-width: 500px) {
  .section-heading {
    font-size: 22px;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.tj {
  text-align: justify;
}
